function setupResults(){
	sectionResize();
    init();
    animations();
    //total shares
    $total = 0;
    $('.share-number').each(function(){
        $this = $(this).html();
        $thisNum = Number($this);
        $total += $thisNum
    });
    $('.js-total').html($total);
    function sectionResize() {

        function resize() {
            var windowHeight    = $(window).height(),
                mainHeight      = $('.dynamic-section').height(),
                bottomHeight    = $('.section-social-shares').height(),
                headerHeight    = $('.site-header').height(),
                footerHeight    = $('.site-footer').height();

            var heightToSet = windowHeight - headerHeight - footerHeight - bottomHeight;

            if (mainHeight <= heightToSet) {
                $('.dynamic-section').height(heightToSet);
            } else {
                $('.dynamic-section').height('auto');
            }
        }

        resize();

        $(window).resize(function () {
            resize();
        });

    }
    function init() {
        var baseScale = 0.25/$(window).height();
        var scale = baseScale * ($(window).height() - $(window).scrollTop());
        var scale = (0.25 - scale) + 1;

        $('.banner .background-cover').css({'transform':'scale('+scale+','+scale+')'});
        $('.Landing .banner .hermit').removeClass('hermit move-up').addClass('move-up-complete');
        $('.Landing .banner .zoom-in').removeClass('zoom-in');

    }
    function animations(){

        $(document).on('click', '.scroll-down-arrow', function() {
            $('html, body').animate({
                scrollTop: $(".intro").offset().top - 90
            }, 1000);
        });

        $(document).on('click', '.scroll-to', function(){
            var target = $(this).data('scroll-to');
            $('html, body').animate({
                scrollTop: $(".scroll-target[data-scroll-target='"+target+"']").offset().top - 90
            }, 1000);
        });

        $(document).on('click', '.menu-toggle', function(){
            $('.menu, .menu-toggle').toggleClass('open');
        });


        $(window).scroll(function(){
            if($(window).scrollTop() >= $(window).height()/2){
                $('.intro .navbar').addClass('show-mobile');
            }else{
                $('.intro .navbar').removeClass('show-mobile');
            }

            if($(window).scrollTop() >= $(window).height()){
                $('.intro .navbar').addClass('fixed');
            }else{
                $('.intro .navbar').removeClass('fixed');
                var baseScale = 0.25/$(window).height();
                var scale = baseScale * ($(window).height() - $(window).scrollTop());
                var scale = (0.25 - scale) + 1;
                $('.banner .background-cover').css({'transform':'scale('+scale+','+scale+')'});
            }

            $('section').each(function(){
                var section = $(this);

                if(section.data('scroll-target') == 'intro' && !section.hasClass('done-count')){
                    section.addClass('done-count');
                    $('.users-number').countTo();
                }

                //animate in all hermit elements
                var eTop = section.offset().top;
                var fromTop = eTop - $(window).scrollTop();
                if(fromTop < ($(window).height()/3*2)){
                    section.find('.hermit').removeClass('hermit move-up').addClass('move-up-complete');
                }
            });
        });

    }


}