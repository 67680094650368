
function setupHome(){
    init();
    animations();
    signupValidation();

    function init() {
        var baseScale = 0.25/$(window).height();
        var scale = baseScale * ($(window).height() - $(window).scrollTop());
        var scale = (0.25 - scale) + 1;

        $('.banner .background-cover').css({'transform':'scale('+scale+','+scale+')'});
        $('.Landing .banner .hermit').removeClass('hermit move-up').addClass('move-up-complete');
        $('.Landing .banner .zoom-in').removeClass('zoom-in');

    }
    function animations(){

        $(document).on('click', '.scroll-down-arrow', function() {
            $('html, body').animate({
                scrollTop: $(".intro").offset().top - 90
            }, 1000);
        });

        $(document).on('click', '.scroll-to', function(){
            var target = $(this).data('scroll-to');
            $('html, body').animate({
                scrollTop: $(".scroll-target[data-scroll-target='"+target+"']").offset().top - 90
            }, 1000);
        });

        $(document).on('click', '.menu-toggle', function(){
            $('.menu, .menu-toggle').toggleClass('open');
        });


        $(window).scroll(function(){
            if($(window).scrollTop() >= $(window).height()/2){
                $('.intro .navbar').addClass('show-mobile');
            }else{
                $('.intro .navbar').removeClass('show-mobile');
            }

            if($(window).scrollTop() >= $(window).height()){
                $('.intro .navbar').addClass('fixed');
            }else{
                $('.intro .navbar').removeClass('fixed');
                var baseScale = 0.25/$(window).height();
                var scale = baseScale * ($(window).height() - $(window).scrollTop());
                var scale = (0.25 - scale) + 1;
                $('.banner .background-cover').css({'transform':'scale('+scale+','+scale+')'});
            }

            $('section').each(function(){
                var section = $(this);

                if(section.data('scroll-target') == 'intro' && !section.hasClass('done-count')){
                    section.addClass('done-count');
                    $('.users-number').countTo();
                }

                //animate in all hermit elements
                var eTop = section.offset().top;
                var fromTop = eTop - $(window).scrollTop();
                if(fromTop < ($(window).height()/3*2)){
                    section.find('.hermit').removeClass('hermit move-up').addClass('move-up-complete');
                }
            });
        });

     }
    var slides = $('.slides');
    function setHeight() {
        if($(window).outerWidth() < 992) {
            var viewHeight = $(window).outerHeight();
            var headerHeight = $('#js-header').outerHeight();
            var height = viewHeight - headerHeight;
            $('.slides').css('height', height + 'px');
        }
    }
    setHeight(slides);
    $(window).resize( function() {
        setHeight(slides);
    });
    var slidesA = document.getElementsByClassName('item');
    var slider = function(target, time){
        var counter = 1;
        //console.log(counter);
        setInterval(function(){
            //console.log('test');
            if(counter < target.length ) {
                for( i=0; i<target.length; i++ ) {
                    target[i].classList.remove('active');
                }
                target[counter].classList.add('active');
            } else {
                counter = 0;
                for( i=0; i<target.length; i++ ) {
                    target[i].classList.remove('active');
                }
                target[counter].classList.add('active');
            }
            counter++;
        }, time);
    };
    slider(slidesA, 6000);
    var mobSlider = function(button, time) {
        button.on("click", function() {
            $height = $(window).outerHeight();
            //$(window).scrollTop($height);
            $('html, body').animate({
                scrollTop: $height
            }, time);
        });
    };
    $element = $('#js-scroll-down');
    mobSlider($element, 400);

    function signupValidation() {

        var errorMessages = {
            firstName: 'First Name is required', 
            lastName: 'Last Name is required',
            noEmail: 'Email Address is required',
            invalidEmail: 'Please enter a valid email address',
            noPhone: 'Phone number is required',
            invalidPhone: 'Please enter a valid phone number',
            country: 'Please select your country',
            state: 'Please select your state',
            city: 'Please select your city',
        };
        var messagesToDisplay = [];

        function displayErrorMessage() {

            $('form .errors-box').show();
            $('form .errors-box').empty();

            for (var message in messagesToDisplay) {
                $('form .errors-box').append('<span class="error"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>' + messagesToDisplay[message] + '</span>');
            }
        }

        function validated() {
            var inputValFirstName   = $('form #first-name').val(),
                inputValLastName    = $('form #last-name').val(),
                inputValEmail       = $('form #email').val(),
                inputValPhone       = $('form #phone').val(),
                inputValCountry     = $('form #country').val();
                inputValState       = $('form #state').val();
                inputValCity        = $('form #city').val();


            messagesToDisplay = [];

            function firstNameValidated() {
                if (inputValFirstName) {
                    return true;
                } else {
                    messagesToDisplay.push(errorMessages.firstName);
                    return false;
                }
            }

            function lastNameValidated() {
                if (inputValLastName) {
                    return true;
                } else {
                    messagesToDisplay.push(errorMessages.lastName);
                    return false;
                }
            } 

            function emailValidated() {

                var regExpression = /\S+@\S+\.\S+/;

                if (inputValEmail) {
                    if (regExpression.test(inputValEmail)) {
                        return true;
                    } else {
                        messagesToDisplay.push(errorMessages.invalidEmail);
                        return false;
                    }
                } else {
                    messagesToDisplay.push(errorMessages.noEmail);
                    return false;
                }
            }

            function phoneValidated() { 

                var regExpression = /^(?=.*[0-9])[- +()0-9]+$/;

                if (inputValPhone) {
                    if (regExpression.test(inputValPhone)) {
                        return true;
                    } else {
                        messagesToDisplay.push(errorMessages.invalidPhone);
                        return false;
                    }
                } else {
                    messagesToDisplay.push(errorMessages.noPhone);
                    return false;
                }

                    
            }

            function countryValidated() {

                if (inputValCountry) {
                    return true;
                } else {
                    messagesToDisplay.push(errorMessages.country);
                    return false;
                }
            }

            function stateValidated() {
                
                if (inputValCountry!="Australia" || inputValCountry) {
                    return true;
                } else {
                    messagesToDisplay.push(errorMessages.state);
                    return false;
                }
            }

            

            if (firstNameValidated() && lastNameValidated() && emailValidated() && phoneValidated() && countryValidated() && stateValidated()) {
                return true;
            } else {
                return false;
            }

        }

        $(document).on('click', 'form .form-submit', function (e) {

            if (!validated()) {
                e.preventDefault();
                displayErrorMessage();
            }

        });

        //modal country select, show aus state or uk city.
        $('select.country').on('change', function(){
            var selectedVal = this.value;
            if( selectedVal == "Australia" ){
                $('.aus-states').show();
            } else {
                $('.aus-states').hide();
            }
            if( selectedVal == "United Kingdom" ){
                $('.uk-cities').show();
            } else {
                $('.uk-cities').hide();
            }
        });


    }

 }
