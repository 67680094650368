function setupQuestions(){
	processQuestions();
	// sectionResize();

	console.log('working');

	function processQuestions() {

		// set the first question to show
		$('.question').first().addClass('active');
		$('.question').first().attr('data-shown', "1");
		$('.btn-prev').addClass('hidden');

		var answers = []; 
		$(document).on('click', '.questions .btn-next', function(e){

			e.preventDefault();

			$('.btn-prev').removeClass('hidden');

			// get and set the answer
			var currentQuestion = $('.questions .question.active');
			var answer = $(currentQuestion).find('.range-slider input').val();
			$(currentQuestion).attr('data-answer', answer);


			if($('.question.active').nextAll('[data-answer=""]').length){
				var nextQ = $('.question.active').removeClass('active').nextAll('[data-answer=""]').first();
				$(nextQ).addClass('active');
				$(nextQ).attr('data-shown', "1");
			}else{
				$(currentQuestion).hide();
				$(this).hide();
				$('.btn-prev').hide();
				$('.loader').show();

				$('.questions .question').each(function (){
					var data = {
						"id":$(this).attr('data-id'),
						"answer":$(this).attr('data-answer')
					}
					answers.push(data);
				});

				var str = JSON.stringify(answers);
				$("#answers").val(str);
				$("#answers").parents("form").submit();

			}
		});

		$(document).on('click', '.btn-prev', function(e){

			e.preventDefault();

			var previousQuestion = $('.question.active').prevAll('[data-shown="1"]').first();

			var questionCount = $('.questions .question').length;
			var currentQuestionIndex = $(".question").index($('.question.active')) - 1;
			var percentComplete = Math.round((100/questionCount)*currentQuestionIndex);
			$('.percentage .number').text(percentComplete + '%');
			$('.bar .inner').css({
				'width': percentComplete + '%'
			});
			
			if($('.question.active').prevAll('[data-shown="1"]').length){
				$('.question.active').attr('data-shown', "");
				$('.question.active').attr('data-answer', "");
				$('.question.active').removeClass('active').prevAll('[data-shown="1"]').first().addClass('active');
			};

			if(percentComplete==0)
			{
				$('.btn-prev').addClass('hidden');
			}

		});
	}



	function sectionResize() {

		function resize() {
			var windowHeight 	= $(window).height(),
				mainHeight		= $('.section-questions > .container').height(),
				bottomHeight	= $('.section-banner').height(),
				headerHeight 	= $('.site-header').height(),
				footerHeight	= $('.site-footer').height();

			var heightToSet = windowHeight - headerHeight - footerHeight - bottomHeight;

			if (mainHeight <= heightToSet) {
				$('.section-questions').height(heightToSet);
			}
		}

		resize();

		$(window).resize(function () {
			resize();
		});

	}

}


